<template>
    <div class="main-content section section-forgotten-password" style="padding-top: 0">

        <!-- Header -->
        <div class="header bg-gradient py-7 py-lg-8 pt-lg-9">
            <div class="container">
                <div class="header-body text-center mb-7">
                    <div class="row justify-content-center">
                        <div class="col-xl-5 col-lg-6 col-md-8 px-5 mb-5">
                          <router-link :to="home">
                            <img src="/assets/img/logo-NL-avec-SJ-blanc.png" alt=""/>
                          </router-link>
                        </div>
                        <div class="align-content-end">
                            <LangSelector @onLangChange="onLangChange" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Page content -->
        <div class="container mt--8 pb-5">
            <div class="row justify-content-center mb-5">
                <div class="col-lg-4 col-md-12 p-0">
                    <div class="card bg-secondary border-0 mb-0">
                        <div class="card-header bg-transparent">
                            <div class="text-muted text-center mt-2 w-100">
                                <h1>{{ $t('oubli.title') }}</h1>
                            </div>
                        </div>
                        <div class="card-body px-lg-5 py-lg-5">
                            <form>
                                <div class="form-group mb-3 pt-4 pt-lg-0">
                                    <div class="input-group input-group-merge input-group-alternative">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fas fa-at" /></span>
                                        </div>
                                        <input
                                            v-model="email"
                                            class="form-control"
                                            name="username"
                                            placeholder="Email"
                                            type="email"
                                        />
                                    </div>
                                </div>

                                <div class="text-center">
                                    <button class="btn btn-third mt-5 mb-2 mb-xl-4" @click.prevent="doForgetPassword">
                                        <i v-if="loader" class="fas fa-circle-notch fa-spin mr-1" />
                                        {{ $t('oubli.resetPassword') }}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-6" />
                        <div class="col-6 text-right">
                            <router-link to="/login" class="text-light">
                                <small>{{ $t('oubli.backToLogin') }}</small>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

            <Footer :fixed="!isMobile" />
        </div>

        <div class="d-none d-xl-block">
            <img class="bg-zebre-forgotten-password" src="/assets/img/zebre-cle-qui-bouge-avec-herbes.svg" />
        </div>
        <Toaster ref="toaster" />
    </div>
</template>

<style>
    .bg-zebre-forgotten-password {
        position: absolute;
        width: 60vh;
        bottom: 0;
        z-index: 0;
    }
</style>

<script>
    import LangSelector from '@/views/Public/LangSelector.vue'
    import Toaster from '@/components/UI/Toaster'
    import PublicMixin from '@/views/Public/PublicMixin'
    import { mapActions } from 'vuex'

    export default {
        name: 'ForgottenPassword',
        title: 'register.forgotten-password',
        components: {
            LangSelector,
            Toaster
        },
        mixins: [PublicMixin],
        data: function () {
            return {
                message: {},
                loader: false,
                email: null
            }
        },
        created() {},
        methods: {
            ...mapActions('auth', ['resetPassword']),
            onLangChange(lang) {
                this.$i18n.locale = lang
            },
            doForgetPassword() {
                this.loader = true
                this.resetPassword(this.email)
                    .then(() => {
                        this.$store.dispatch('toast', {
                            type: 'success',
                            title: this.$t('oubli.resetConfirmation')
                        })
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loader = false
                    })
            }
        }
    }
</script>